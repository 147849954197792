// ============================
//     RTL css start
// ============================

$i: 0;
@while $i<=50 {
  .p {
    &-l-#{$i} {
      padding-right: #{$i}px;
      padding-left: 0;
      @if ($i == 0) {
        &[class*="col"] {
          padding-left: 15px;
        }
      }
    }

    &-r-#{$i} {
      padding-left: #{$i}px;
      padding-right: 0;
      @if ($i == 0) {
        &[class*="col"] {
          padding-right: 15px;
        }
      }
    }
  }

  .m {
    &-l-#{$i} {
      margin-right: #{$i}px;
      margin-left: 0;
    }

    &-r-#{$i} {
      margin-left: #{$i}px;
      margin-right: 0;
    }
  }
  $i: $i + 5;
}
ol:not([class]),
ul:not([class]) {
  padding-left: 0;
  padding-right: 2rem;
}
dl,
.list-inline,
.list-group,
.list-unstyled {
  padding-right: 0;
  padding-left: 0;
}
dd {
  margin-right: 0;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: right;
}
.dropdown-menu {
  text-align: right;
}
.dropdown .dropdown-item i {
  margin-right: 0;
  margin-left: 10px;
}
.alert-dismissible {
  padding-right: $alert-padding-x;
  padding-left: 3.75rem;
  .btn-close {
    right: auto;
    left: 0;
  }
}
.accordion-button::after {
  margin-right: auto;
  margin-left: 0;
}
.pc-container {
  margin-right: $sidebar-width;
  margin-left: 0px;
  @media (max-width: 1024px) {
    margin-right: 0px;
  }
}

.pct-c-btn {
  right: auto;
  left: 0;
  border-left: none;
  border-right: 4px solid $card-bg;
  border-radius: 4px 50% 50% 4px;
  a {
    padding: 12px 16px 12px 12px;
  }
}
.pc-sidebar {
  border-left: var(--pc-sidebar-border);
  border-right: none;
  ul {
    padding-right: 0;
  }
  .pc-badge,
  .pc-arrow {
    float: left;
  }
  .pc-micon {
    margin-right: 0;
    margin-left: 15px;
  }
  @media (min-width: 1025px) {
    &.pc-sidebar-hide {
      transform: translateX(260px);
      ~ .pc-footer,
      ~ .pc-container {
        margin-right: 0px;
      }
      ~ .pc-header {
        right: 0;
      }
    }
  }
  @media (max-width: 1024px) {
    left: auto;
    right: -#{$sidebar-width};
    &.mob-sidebar-active {
      right: 0;
    }
    ~ .pc-header {
      right: 0;
    }
  }
}
.pc-sidebar {
  .pc-submenu {
    .pc-link {
      padding: 10px 65px 10px 30px;
      &:after {
        left: auto;
        right: 45px;
      }
    }
    .pc-submenu {
      .pc-link {
        padding: 10px 85px 10px 30px;
        &:after {
          left: auto;
          right: 65px;
        }
      }
      .pc-submenu {
        .pc-link {
          padding: 10px 105px 10px 30px;
          &:after {
            left: auto;
            right: 85px;
          }
        }
      }
    }
  }
  .pc-navbar > li > .pc-submenu::before {
    right: 40px;
    left: auto;
  }
}
.pc-header {
  left: 0;
  right: $sidebar-width;
  ul {
    padding-right: 0;
  }
  .m-header {
    padding: 16px 24px 16px 10px;
  }
  .user-avtar {
    margin-right: 0;
    margin-left: 10px;
  }
  .header-search {
    .icon-search {
      left: auto;
      right: 15px;
    }
    .btn-search {
      right: auto;
      left: 9px;
    }
  }
  .pc-h-dropdown {
    &:not(.dropdown-menu-end) {
      right: 0 !important;
      left: auto !important;
    }
    &.dropdown-menu-end {
      right: auto !important;
      left: 0 !important;
    }
  }

  .dropdown-user-profile .upgradeplan-block {
    &::before {
      right: auto;
      left: -70px;
    }
    &::after {
      right: auto;
      left: -150px;
    }
  }
  .dropdown-user-profile .settings-block .form-switch {
    padding-left: 2.5em;
    .form-check-input {
      float: left;
    }
  }
}
.page-header {
  h5,
  .h5 {
    border-left: 1px solid darken($body-bg, 10%);
    border-right: none;
    margin-right: 0;
    padding-right: 0;
    margin-left: 8px;
    padding-left: 8px;
  }
}
.pc-footer {
  margin-left: 0px;
  margin-right: $sidebar-width;
  @media (max-width: 1024px) {
    margin-right: 0px;
  }
}

.social-widget-card i {
  right: auto;
  left: 14px;
}
.table tr td .row {
  margin-right: 0;
  margin-left: 50px;
}
.task-card {
  li {
    padding-left: 0;
    padding-right: 30px;
    .task-icon {
      right: 3px;
      left: auto;
    }
  }
  .task-list {
    &:after {
      right: 10px;
      left: auto;
    }
    &:before {
      right: 3px;
      left: auto;
    }
  }
}
.new-cust-card .align-middle .status {
  left: 0;
  right: auto;
}
.apexcharts-legend-marker {
  margin-left: 3px;
}
.btn-group {
  .btn {
    border-radius: var(--bs-btn-border-radius) !important;
    &:not(:last-child):not(.dropdown-toggle) {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
    &:not(:first-child) {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }
}

// .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
// .btn-group > .btn.dropdown-toggle-split:first-child,
// .btn-group > .btn-group:not(:last-child) > .btn {
// }
.page-item {
  &:not(:first-child) .page-link {
    margin-left: 0;
    margin-right: -1px;
  }
  &:last-child .page-link {
    border-top-left-radius: var(--bs-pagination-border-radius);
    border-bottom-left-radius: var(--bs-pagination-border-radius);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:first-child .page-link {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--bs-pagination-border-radius);
    border-bottom-right-radius: var(--bs-pagination-border-radius);
  }
}
.dropdown-toggle::after {
  margin-left: 0;
  margin-right: 0.255em;
}
.modal-header .btn-close {
  margin: calc(var(--bs-modal-header-padding-y) * -0.5) auto calc(var(--bs-modal-header-padding-x) * -0.5)
    calc(var(--bs-modal-header-padding-y) * -0.5);
}
.toast-header .btn-close {
  margin-right: var(--bs-toast-padding-x);
  margin-left: calc(var(--bs-toast-padding-x) * -0.5);
}
.notifier-container {
  right: auto;
  left: 4px;
  .notifier:not(.shown) {
    transform: translateX(-100%);
  }
}
.vtree,
pre[class*="language-"] > code {
  direction: ltr;
  text-align: left;
}
select.form-control,
select.custom-select,
select.dataTable-selector,
select.dataTable-input,
.form-select {
  background-position: left $form-select-padding-x center;
}
.form-check {
  padding-left: 0;
  padding-right: $form-check-padding-start;
  .form-check-input {
    float: right;
    margin-right: $form-check-padding-start * -1;
  }
}
select.custom-select,
select.dataTable-selector,
.form-select {
  padding-left: $form-select-indicator-padding;
  padding-right: $form-select-padding-x;
}
.form-switch {
  padding-left: 0;
  padding-right: $form-switch-padding-start;

  .form-check-input {
    margin-right: $form-switch-padding-start * -1;
    background-position: right center;
    &:checked {
      background-position: left center;
    }
  }
}
.custom-switch-v1.form-switch {
  padding-right: 2.9em;
  .form-check-input {
    margin-right: -2.9em;
  }
}
.input-group {
  > * {
    border-radius: $input-border-radius !important;
  }
  &:not(.has-validation) {
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
    > .dropdown-toggle:nth-last-child(n + 3),
    > .form-floating:not(:last-child) > .form-control,
    > .form-floating:not(:last-child) > .form-select {
      // @include border-start-radius(0);
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
  $validation-messages: "";
  @each $state in map-keys($form-validation-states) {
    $validation-messages: $validation-messages +
      ":not(." +
      unquote($state) +
      "-tooltip)" +
      ":not(." +
      unquote($state) +
      "-feedback)";
  }
  > :not(:first-child):not(.dropdown-menu):not(.form-floating)#{$validation-messages},
  > .form-floating:not(:first-child) > .form-control,
  > .form-floating:not(:first-child) > .form-select {
    margin-right: -$input-border-width;
    margin-left: 0;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
.form-floating > label {
  width: auto;
  left: auto;
  right: 0;
}
.form-check-inline {
  margin-right: 0;
  margin-left: 1rem;
}
.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  margin: 0 8px 0 -4px;
  border-left: none;
  border-right: 1px solid rgba(255, 255, 255, 0.35);
}
.choices[data-type*="select-one"]:after {
  right: auto;
  left: 11.5px;
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: auto;
  left: 0;
}
.editor-toolbar,
.CodeMirror {
  direction: ltr;
  text-align: left;
}
.dataTable-sorter::before,
.dataTable-sorter::after {
  right: auto;
  left: 4px;
}
.dataTable-dropdown label select {
  margin-right: 0;
  margin-left: 8px;
}
.dataTables_length select.form-select {
  padding-right: 0.7rem;
  padding-left: 30px;
}
div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0;
  margin-right: 0.5em;
}
div.dataTables_wrapper div.dataTables_filter {
  text-align: left;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control {
  padding-left: 0.75rem;
  padding-right: 30px;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
  left: auto;
  right: 5px;
}
.pc-icon-checkbox {
  padding-right: 0;
}
#cke5-inline-demo {
  .demo-row {
    .demo-row__half {
      &:first-of-type {
        padding-right: 0;
        padding-left: 0.5rem;
      }

      &:last-of-type {
        padding-right: 0.5rem;
        padding-left: 0;
      }
    }
  }
}
.mail-wrapper {
  .mail-menulist {
    margin-right: 0;
    margin-left: var(--bs-gutter-x);
  }
}
.mail-option .mail-buttons {
  right: auto;
  left: 5px;
  transform-origin: left;
}
.mail-wrapper .mail-menulist .list-group-item-action .material-icons-two-tone{
  margin-left: 8px;
  margin-right: 0;
}
.nav {
  padding-right: 0;
}
.chat-wrapper {
  .chat-user_list {
    margin-right: 0;
    margin-left: var(--bs-gutter-x);
  }
  .chat-user_info {
    margin-right: var(--bs-gutter-x);
    margin-left: 0;
  }
}
.table-card .card-body,
.table-body.card-body {
  .table {
    tr {
      td,
      th {
        &:first-child {
          padding-right: 25px;
          padding-left: 0.75rem;
        }

        &:last-child {
          padding-right: 25px;
          padding-left: 0.75rem;
        }
      }
    }
  }
}

.dataTable-table th a {
  margin-left: 16px;
  padding-right: 0;
}
.list-inline-item:not(:last-child) {
  margin-right: 0;
  margin-left: 0.5rem;
}
.was-validated .form-select:valid:not([multiple]):not([size]),.was-validated .form-select:valid:not([multiple])[size="1"],.form-select.is-valid:not([multiple]):not([size]),.form-select.is-valid:not([multiple])[size="1"],.was-validated .form-select:invalid:not([multiple]):not([size]),.was-validated .form-select:invalid:not([multiple])[size="1"],.form-select.is-invalid:not([multiple]):not([size]),.form-select.is-invalid:not([multiple])[size="1"] {
  background-position: left 0.75rem center, center left 2.75rem;
}
.was-validated textarea.form-control:invalid, .was-validated textarea.custom-select:invalid, .was-validated textarea.dataTable-selector:invalid, .was-validated textarea.dataTable-input:invalid, textarea.form-control.is-invalid, textarea.is-invalid.custom-select, textarea.is-invalid.dataTable-selector, textarea.is-invalid.dataTable-input,
.was-validated textarea.form-control:valid, .was-validated textarea.custom-select:valid, .was-validated textarea.dataTable-selector:valid, .was-validated textarea.dataTable-input:valid, textarea.form-control.is-valid, textarea.is-valid.custom-select, textarea.is-valid.dataTable-selector, textarea.is-valid.dataTable-input{
  background-position: top calc(0.375em + 0.4rem) left calc(0.375em + 0.4rem);
  padding-left: calc(1.5em + 1.6rem);
  padding-right: 0.75rem;
}
.was-validated .form-control:invalid, .was-validated .custom-select:invalid, .was-validated .dataTable-selector:invalid, .was-validated .dataTable-input:invalid, .form-control.is-invalid, .is-invalid.custom-select, .is-invalid.dataTable-selector, .is-invalid.dataTable-input,
.was-validated .form-control:valid, .was-validated .custom-select:valid, .was-validated .dataTable-selector:valid, .was-validated .dataTable-input:valid, .form-control.is-valid, .is-valid.custom-select, .is-valid.dataTable-selector, .is-valid.dataTable-input{
  background-position : left calc(0.375em + 0.4rem) center;
  padding-left: calc(1.5em + 1.6rem);
  padding-right: 0.75rem;
}
.pc-footer {
  .footer-link .list-inline-item {
    &:not(:last-child) {
      margin-right: 0;
      margin-left: 0.9rem;
    }
  }
}
.cropper {
  direction: ltr;
}
.user-group{
  .avtar,
  img{
    + .avtar,
    + img {
      margin-left: 0;
      margin-right: -14px;
    }
  }
}
.auth-main .auth-wrapper .auth-form img + span{
  padding-left: 0;
  padding-right: 10px;
}
.progress-stacked {
  overflow: hidden;
  .progress:not(:first-child) {
    .progress-bar {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
  .progress:not(:last-child) {
    .progress-bar {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
// RTL css end
